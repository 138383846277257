.container {
	width: (1150 / 1200) * 100%;
	max-width: 1150px;
	margin: 0 auto;
}
@media (max-width:1200px){
	.container {
		width: 100%;
		max-width:inherit;
	}
}
@media screen and (min-width: 1200px) {
	.container {
		padding: 125px 0;
	}
}
@media (max-width: 980px) {
	.container {
		padding: 125px 25px;
	}
}
@media (max-width: 800px) {
	.container {
		padding: 125px 25px;
	}
}
@media (max-width: 480px) {
	.container {
		padding: 0 25px;
	}
}
@media (max-width: 320px) {
	.container {
		padding: 0 10px;
	}
}