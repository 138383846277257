/*
	Styles for the proven banner, which is the first white section on the homepage
*/

#proven {
	.container {
		.element {
			&:first-child {
				width: 529px;
			}
			&:nth-child(2) {
				margin-left: 58px;
				width: 558px;
			}
		}
		.delivery-contact {
			margin-bottom: 20px;
			margin-top: 50px;
		}
		h2 {
			margin-top: 0;
			// padding-left: 2px;
		}
	}
}
@media (max-width: 800px) {
	#proven {
		.container {
			padding: 25px;
			h2 {
				font-size: 35px;
				span {
					white-space:nowrap;
				}
			}
		}
		.delivery-contact {
			flex-direction: column;
		}
		.element:first-child, .element:nth-child(2) {
			width: calc(100%/2);
		}
		.text-button {
			padding: 25px 0;
			text-align: left;
			width: 100%;
		}
	}
}
@media (max-width: 667px) {
	#proven {
		.container {
			.flex {
				display: block;
				.element:nth-child(2), 
				.element:first-child {
					width: auto;
				}
			}
			.element {
				
				h2 {
					margin-bottom: 25px;
				}
				&:nth-child(2) {
					margin-left: 0;
				}
			}
			.delivery-contact {
				.green-button {
					margin: 0 auto;
				}
				.text-button {
					text-align: center;
					margin: 15px 0 0 0;
				}
			}
			ul.terms {
				text-align: center;
				width: 100%;
				li {
					float: none;
				}
			}
		}
	}
}
@media (max-width: 480px) {
	#proven {
		.terms {
			display: flex;
			justify-content: center;
		}
	}
}

@media (max-width: 375px) {
	#banner .river-cinemagraph {
		
	}
}