/*
	Just the biker banner section of the homepage
	1. Load the default static jpg version of the river graphic.
	2. Javascript (Modernizr) checks for touchevent support
		3a. client supports touch, then we serve the cinema graphic
		3b. client doesn't support touch, then we server the cinema video
*/



// DEFAULT CSS
.river-cinemagraph {
  height: 394px;
  margin-top: 100px;
  position: relative;
  width: 100%;
  overflow: hidden;
	.container {
		padding: 0;
		height: 100%;
		position: relative;
		.info {
			// float: left;
			margin-top: 50px;
			// width: 100px;
			position: relative;
			z-index: 11;
			.green-button {
				margin-top: 40px;
			}
			h3 {
				color: $blue-color;
				font-family: $hel-bold;
				font-size: 52px;
				font-weight: bold;
				line-height: 35px;
				margin-bottom: 15px;
				text-transform: uppercase;
				text-shadow:0px 0px 5px white;
			}
			p {
				color: $blue-color;
				font-family: $hel-bold;
				font-size: 38px;
				font-weight: bold;
				line-height: 42px;
				width: 630px;
				text-shadow:0px 0px 5px white;
			}
		}
	}
}
.river-cinemagraph {
  background: url("/img/flo_river_2300.jpg") 0 0 no-repeat;
  background-size:cover;
  .container:after {
  	content:'';
  	display: block;
		background: url(/img/biker-large.png) no-repeat;
		position: absolute;
		bottom: 0;
		right:10%;
		height: 100%;
		width: 395px;
		z-index: 10;
  }
}


@media (max-width: 2100px){
	.river-cinemagraph {
		background: url("/img/flo_river_2100.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1900px){
	.river-cinemagraph {
		background: url("/img/flo_river_1900.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1700px){
	.river-cinemagraph {
		background: url("/img/flo_river_1700.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1500px){
	.river-cinemagraph {
		background: url("/img/flo_river_1500.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1300px){
	.river-cinemagraph {
		background: url("/img/flo_river_1300.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1200px){
	.river-cinemagraph {
		.container {
			padding: 0 20px;
	  }
	}
}
@media (max-width: 1100px){
	.river-cinemagraph {
		background: url("/img/flo_river_1100.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 1000px) {
	.river-cinemagraph {
		.container:after {
			right: 0;
		}
	}
}
@media (max-width: 800px) {
	.river-cinemagraph {
	  height: 520px;
		background: url("/img/flo_river_800.jpg") 0 0 no-repeat;
  	background-size:cover;
		.container {
			// padding: 0 20px;
			.info {
				p {
					line-height: 42px;
					width: 409px;
				}
			}
			&:after {
				height: 416px;
				background: url(/img/biker-medium.png) no-repeat;
			}
		}
	}
}
@media (max-width: 768px) {
	.river-cinemagraph .container	{
		.info {
			margin-top: 70px;
		}
		&:after {
			right: -15vw;
		}
	}
}
@media (max-width: 600px){
	.river-cinemagraph {
		background: url("/img/flo_river_600.jpg") 0 0 no-repeat;
  	background-size:cover;
	}
}
@media (max-width: 480px) {
	.river-cinemagraph {
		height: 590px;
		margin-top: 107px;
		background: url("/img/flo_river_480.jpg") 0 0 no-repeat;
  	background-size:cover;
		.container {
			.info {
				h3 {
					margin-top: 75px;
				}
				p {
					width: 316px;
				}
				.green-button {
					left: 50%;
					position: relative;
					transform:translateX(-50%);
					margin: 120px 0 0 0;
				}
			}
			&:after {
				right: -25vw;
			}
		}
	}
}

@media (max-width : 414px) {
	.river-cinemagraph {
		.container {
			&:after {
				right: -35vw;
			}
		}
	}
}
@media (max-width:375px) {
	.river-cinemagraph {
		margin-top: 90px;
		height: 560px;
	}
	.river-cinemagraph .container .info p {
		font-size: 33px;
	}
}
@media (max-width: 360px) {
	.river-cinemagraph .container .info p {
		font-size: 36px;
	}
}
@media (max-width: 320px) {
	.river-cinemagraph {
		.container {
			padding: 0 5px;
			.info {
				height: 530px;
				width: 315px;
				p {
					font-size: 21px;
					width: auto;
				}
			}
		}
	}
}
// VIDEO CSS
.river-cinemagraph.cinema-video {
	video {
		width: 100%;
		height: auto;
		position: absolute;
		top:50%;
		left:50%;
		z-index: 1;
		transform:translate(-50%,-50%);
	}
}
@media (max-width:800px){
	.river-cinemagraph.cinema-video {
		video {
			width: auto;
			height: 100%;
		}
	}
}
/*
	GRAPHIC CSS
	Modernizr decided we are on mobile or tablet, so we will serve the animated gif background with the biker as an :after
*/
.river-cinemagraph.cinema-graphic {
  background: url("/img/flo_river_2300.gif") 0 0 no-repeat, url("/img/flo_river_2300.jpg") 0 0 no-repeat;
  background-size:cover, cover;
}
@media (max-width: 2100px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_2100.gif") 0 0 no-repeat, url("/img/flo_river_2100.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 1900px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_1900.gif") 0 0 no-repeat, url("/img/flo_river_1900.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 1700px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_1700.gif") 0 0 no-repeat, url("/img/flo_river_1700.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 1500px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_1500.gif") 0 0 no-repeat, url("/img/flo_river_1500.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 1300px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_1300.gif") 0 0 no-repeat, url("/img/flo_river_1300.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 1100px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_1100.gif") 0 0 no-repeat, url("/img/flo_river_1100.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 800px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_800.gif") 0 0 no-repeat, url("/img/flo_river_800.jpg") 0 0 no-repeat;
		background-size:cover,cover;
		// height: 520px;
	}
}
@media (max-width: 600px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_600.gif") 0 0 no-repeat, url("/img/flo_river_600.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}
@media (max-width: 480px){
	.river-cinemagraph.cinema-graphic {
		background: url("/img/flo_river_480.gif") 0 0 no-repeat, url("/img/flo_river_480.jpg") 0 0 no-repeat;
  	background-size:cover,cover;
	}
}

@media (max-width: 375px){
	.river-cinemagraph.cinema-graphic {
		margin-top: 90px;
		// height: 560px;
	}
}