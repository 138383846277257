/* Fonts */
@font-face {
	font-family: 'Helvetica Neue Bold';
	src: url("../fonts/HelveticaNeueLTPro-Bd.otf"); 
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url("../fonts/HelveticaNeueLTPro-Roman.otf"); 
}

/* Variables */
$green-color: #00966A;
$blue-color: #16438E;
$hel-reg: 'Helvetica Neue', sans-serif;
$hel-bold: 'Helvetica Neue Bold', sans-serif;

/* Structure */

html, body {
	font-family: $hel-reg, Helvetica, Arial, sans-serif !important;
	height: 100%;
}

/* Elements */
h2, h4, h5 {
	font-family: $hel-bold;
	font-weight: bold;
}

h2 {
	font-size: 34px;
	line-height: 45px;
}

h3, h4 {
	font-size: 24px;
	line-height: 35px;
	margin: 0;
}

p {
	font-size: 18px;
	line-height: 30px;
}

a:hover {
	text-decoration: underline;
}

.tablet-only {
	display: none;
}

/* Reusable Styles */

.blue-text {
	color: $blue-color;
}

.green-text {
	color: $green-color;
}

.inline-bold {
	font-family: $hel-bold;
	font-weight: bold;
}

.underline {
	border-bottom: 2px solid $green-color;
	&:hover, &:active, &:visited, &:focus {
		text-decoration: none;
	}
	&:hover {
		color: $blue-color;
	}
}

.mobile-only {
	display: none !important;
}



/* Terms */
ul.terms {
	margin: 0;
	padding: 0;
	li {
		float: left;
		font-size: 12px;
		list-style: none;
		margin: 0 15px 0 0;
		a {
			color: black;
			text-decoration: underline;
		}
	}
}
/* Buttons */
.blue-button {
	background-color: $blue-color;
	background-color: rgba(22, 67, 142, 0.72);
	color: white;
	height: 150px;
	margin: 0px 15px 40px 0;
	padding: 30px 23px;
	text-align: center;
	width: 275px;
	&:last-child {
		margin-right: 0;
		padding: 32px 50px;
	}
	p {
		font-size: 21px;
		margin: 0;
	}
}
.green-button {
	background-color: $green-color;
	border: 1px solid white;
	border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	-webkit-border-radius: 15px;
	color: white;
	display: block;
	font-size: 18px;
	height: 80px;
	line-height: 20px;
	padding: 12px 35px;
	text-transform: uppercase;
	width: 280px;
	&:hover, &:active, &:visited {
		color: white;
	}
}
.text-button {
	color: $green-color;
	margin: 15px 0 0 15px;
	text-align: center;
	width: 195px;
	a {
		color: $green-color;
		&:hover, &:pressed, &:active, &:visited {
			text-decoration: none;
		}
	}
	p {
		font-family: $hel-bold;
		font-weight: bold;
	}
}
/* Medical ISI Blade */
#isi, #consistent {
	background-color: #EBEBEB;
	width: 100%;
	.container {
		padding: 25px 0;
		a {
			color: $blue-color;
			text-decoration: underline;
		}
		h3 {
			font-size: 18px;
			text-transform: uppercase;
		}
		ul {
			font-size:18px;
			margin-top:-10px;
		}
		p {
			font-size: 18px;
			line-height: 25px;
		}
		p.fda {
			width: 90%;
		}
	}
}
#consistent {
	bottom: 0;
	height: 180px;
	transition: height 500ms ease-in-out;
	position: fixed;
	overflow-y: hidden;
	z-index:99;
	a#toggle {
		background: url(../img/isi-arrow-up.png) no-repeat;
		background-position: right 0;
		float: right;
		font-size: 13px;
		padding: 0 16px;
		text-decoration: none !important;
		text-transform: lowercase;
	}
}

.less {
	background: url(../img/isi-arrow-down.png) no-repeat !important;
	background-position: right 0 !important;
}

.isi-overflow {
	overflow-y: auto !important;
}

/* Reminder Blade */
#reminder {
	background-color: #ebf7f3;
	.container {
		padding-bottom: 60px;
		padding-top: 60px;
		text-align: center;
	}
	p {
		color: $blue-color;
		margin-bottom: 0;
	}
}



/* D2D Program */
#home {
	.container {
		padding: 125px 0;
		h2 {
			margin-bottom: 75px;
		}
	}
	.element {
		width: calc(100%/2 - 15px);
		&:first-child {
			margin-right: 105px;
			.green-button {
				margin: 40px 0;
			}
			.text-button {
				margin: 55px 15px 5px;
			}
			ul.terms {
				margin-top: 20px;
			}
		}
	}
	.center {
		justify-content: center;
	}
	h4:last-child {
		margin-top: 95px;
	}
	ul.drug-checklist {
		font-size: 18px;
		padding: 0;
		margin-bottom: 85px;
		li {
			background: url(../img/check-sprite.png) no-repeat left top;
			list-style: none;
			padding-left: 30px;
			padding-top: 6px;
			margin-bottom: 3px;
		}
	}
}

/* Get Flomax */
#rx {
	.container {
		.flex {
			.element {
				//width: calc(100%/2);
			}
		}
		.element:nth-child(2) {
			text-align: right;
		}
		.green-button {
			float: right;
			margin-top: 50px;
		}
	}
}

/* Pickup Blade */
#pickup {
	.container {
		padding:125px auto;
		.flex {
			.element {
				width: calc(100%/2);
			}
		}
		h2 {
			margin-top: 0;
			width: 482px;
		}
	}
}

/* FAQ */

.faq {
	padding: 80px 0;
	address {
		margin-left: 25px;
	}
	.eligible {
		margin-bottom: 30px;
		p {
			line-height: 5px;
		}
	}
	h2 {
		margin: 50px 0;
	}
	h3 {
		margin-bottom: 10px;
	}
	p {
		line-height: 24px;
		margin: 0 0 20px;
	}
	ul li {
		font-size: 18px;	
	}
}

/* Modals */
.modal {
	background-color: rgba(255, 255, 255, 0.72);
	box-shadow: 0;
	.interstitial {
		a {
			border: 1px solid $blue-color;
			border-radius: 10px;
			-moz-border-radius: 10px;
			-webkit-border-radius: 10px;
			background: linear-gradient(to bottom, #0e4e91, #042c5f);
			background: -webkit-linear-gradient(bottom, #0e4e91, #042c5f);
			background: -o-linear-gradient(bottom, #0e4e91, #042c5f);
			background: -moz-linear-gradient(bottom, #0e4e91, #042c5f);
			box-shadow: inset 1px 1px 1px 1px white;
			color: white;
			font-size: 21px;
			height: 66px;
			padding-top: 20px;
			text-transform: uppercase;
			vertical-align: middle;
			width: 195px;
		}
		a.cancel {
			float: right;
		}
	}
	.modal-content {
		border: 3px solid #8d8f92;
		box-shadow: none;
		height: 403px;
		margin: 150px auto;
		padding: 70px 83px 65px 91px;
		width: 700px;
		p {
			color: #8d8f92;
			font-size: 21px;
			line-height: 30px;
		}
	}
	.terms-modal {
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border: 1px solid $blue-color;
		height: 506px;
		padding: 15px 30px 85px;
		width: 1157px;
		h4 {
			margin-top: 50px;
		}
		p {
			color: black;
			font-size: 18px;
		}
	}
	.nine30 {
		height: auto;
		padding: 15px 2% 5px;
		width: 850px;
		a {
			margin: 0;
		}
		.btn-default {
			background: url(../img/interstitial-arrow.png) no-repeat 99%;
			background-color: #004E80;
			clear: both;
			color: #fff;
			display: inline-block;
			float: left;
			font-size: 16px;
			height: 36px;
			text-decoration: none;
			margin-right: 10px;
			margin-top: 10px;
			padding-top: 7px;
			box-shadow: 0 -6px 3px -4px #0094d5 inset;
			text-transform: uppercase; 
			width: 389px;
		}
		p.one-day {
			margin-top: 30px;
		}
		p.blue-text {
			color: $blue-color !important;
			font-size: 16px;
			font-family: 'Helvetica Neue Bold', sans-serif;
			font-weight: bold;
		}
		p {
			font-size: 16px;
			line-height: 21px;
		}
		.terms {
			display: block;
			clear: both;
			margin-top: 80px;
			.blue-text {
				font-size: 14px;
			}
			p {
				color: black;
				font-size: 12px;
				line-height: 12px;
				margin-bottom: 20px;
				&:first-child {
					margin-bottom: 3px;
					margin-top: 30px;
				}
			}
		}
		.close {
			font-size: 12px;
			margin-bottom: 15px;
		}
	}
	.close {
		background: url(../img/close.png) no-repeat right;
		background-position: right 0px;
		font-size: 14px;
		opacity: 1.0;
		padding: 4px 25px;
		text-transform: uppercase;
		&:hover {
			color: $blue-color;
		}
	}
}

/* Home Animation */
.house_icon {width:84px; height:100px; background-color:#ffffff; overflow:hidden; margin-top: -33px;}
#smoke {visibility:hidden} 
#house_smoke {height:83px; display:block; margin:auto auto}

.interested {
	margin-top: 50px;
	text-align: center;
}

.flex {
	display: flex;
	flex-direction: row;
}


/* Media Queries */

@media only screen and (max-width:1200px) {
	#pickup, #rx, #isi {
		.container {
			padding:25px;
		}
	}
}

@media screen and (max-width: 1000px) {
	#home {
		.center {
			display: block;
			text-align: center;
			h2:first-child {
				margin: 0;
			}
		}
		.house_icon {
			margin: -5px auto 0;
		}
	}
}

@media only screen and (max-width: 980px) {
	#pickup {
		.container {
			.element {
				margin-right: 25px;
				h2 {
					width: auto;
				}
			}
		}
	}
	#rx {
		.container {
			.element {
				margin-left: -88px;
			}
		}
	}
	.modal .terms-modal {
		height: auto;
		padding: 15px 30px 55px;
		width: 90%;
	}
	#home .element:first-child .text-button {
		margin: 35px -2px;
	}
	#isi, #consistent {
		.container {
			p.fda {
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width:800px) {
	#isi {
		.container {
			padding:0;
		}
	}
}

