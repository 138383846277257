/*
	Just the yacht banner section of the homepage
	1. Load the default static jpg version of the yacht graphic.
	2. Javascript (Modernizr) checks for touchevent support
		3a. client supports touch, then we serve the cinema graphic
		3b. client doesn't support touch, then we server the cinema video
*/



// DEFAULT CSS || This is served to all users, regardless of them getting video or animated gif
.yacht-cinemagraph {
	background: url(../img/flomax_yacht_1900.jpg) no-repeat 50% 0;
	background-size: cover;	
	.container {
		padding: 125px 0;
	}
	.element {
		h2 {
			margin: 0 0 35px 0;
			width: 479px;
		}
		h3 {
			margin-bottom: 35px;
			width: 489px;
		}
	}
	.terms {
		margin: 0 auto;
		width: 140px;
	}
}

/*
	CINEMA VIDEO CSS || User is on desktop aka doesn't support touch, so they get the video
*/
.yacht-cinemagraph.cinema-video {
	position: relative;
	overflow:hidden;
	.container {
		position: relative;
		z-index:10;
	}
	video {
		position: absolute;
		top:50%;
		left:50%;
		height:auto;
		width:100%;
		z-index:1;
		transform:translate(-50%, -50%);
	}
}
@media (max-width:1943px) {
	.yacht-cinemagraph.cinema-video {
		video {
			//height: 100%;
			width: auto;
		}
	}
}


@media (max-width: 1200px) {
	.yacht-cinemagraph {	
		.container {
			padding: 25px;
		}
	}
}
@media (max-width: 1200px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_1200.jpg) no-repeat 50% 0;
		background-size: cover;	
	}
}
@media (max-width: 1000px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_1000.jpg) no-repeat 50% 0;
		background-size: cover;	
	}
}
@media (max-width: 800px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_800.jpg) no-repeat 50% 0;
		background-size: cover;	
		.element {
			.flex:nth-child(odd) {
				display: block;
			}
		}
		.text-button {
			padding: 25px 3px;
			text-align: left;
			width: 100%;
		}
		.blue-button {
			height: auto;
		}
	}
}
@media (max-width: 600px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_600.jpg) no-repeat 50% 0;
		background-size: cover;	
	}
}
@media (max-width: 480px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_480.jpg) no-repeat 50% 0;
		background-size: cover;
		.container {
			padding:25px;
			.element {
				h2, h3 {
					width: auto;
				}
				.flex {
					flex-direction: column;
					// &:nth-child(even) {
					// 	display: block;
					// }
					&:nth-child(odd) {
						.green-button {
							margin: 0 auto;
						}
						.text-button {
							margin-left: 0;
							text-align: center;
						}
					}
				}
			}
			ul.terms {
				li {
					margin: 0;
					width: 100%;
				}
			}
		}
		.blue-button {
			margin: 0 0 10px 0;
			&:last-child {
				margin-bottom: 50px;
			}
		}	
	}
}
// @media (max-width : 375px) {
// 	.yacht-cinemagraph .container .element h2, 
// 	.yacht-cinemagraph .container .element h3 {
// 		font-size: 23px;
// 	}
// }
@media (max-width: 360px) {
	.yacht-cinemagraph {
		background: url(../img/flomax_yacht_360.jpg) no-repeat 50% 0;
		background-size: cover;	
	}
}
/*
	CINEMA GRAPH CSS || User is on tablet/mobile aka supports touch, so they get an animated gif
*/
.yacht-cinemagraph.cinema-graphic {
	background: url(../img/flomax_yacht_1900.gif) no-repeat 50% 0, url(../img/flomax_yacht_1900.jpg) no-repeat 50% 0;
	background-size: cover, cover;	
}
@media (max-width: 1200px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_1200.gif) no-repeat 50% 0, url(../img/flomax_yacht_1200.jpg) no-repeat 50% 0;
		background-size: cover, cover;

	}
	.yacht-cinemagraph.cinema-video {
		video {
			//height: 100%;
			top:99%;
			width: auto;
		}
	}
}
@media (max-width: 1000px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_1000.gif) no-repeat 50% 0, url(../img/flomax_yacht_1000.jpg) no-repeat 50% 0;
		background-size: cover, cover;	
	}
}
@media (max-width: 800px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_800.gif) no-repeat 50% 0, url(../img/flomax_yacht_800.jpg) no-repeat 50% 0;
		background-size: cover, cover;	
	}
}
@media (max-width: 600px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_600.gif) no-repeat 50% 0, url(../img/flomax_yacht_600.jpg) no-repeat 50% 0;
		background-size: cover, cover;	
	}
}
@media (max-width: 480px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_480.gif) no-repeat 50% 0, url(../img/flomax_yacht_480.jpg) no-repeat 50% 0;
		background-size: cover, cover;	
	}
}
@media (max-width: 360px) {
	.yacht-cinemagraph.cinema-graphic {
		background: url(../img/flomax_yacht_360.gif) no-repeat 50% 0, url(../img/flomax_yacht_360.jpg) no-repeat 50% 0;
		background-size: cover, cover;	
	}
}
