/*
	FOOTER STYLES
 */
footer {
	//background-color: $blue-color;
	color: white;
	hr.delimiter {
		height:5px;
		background:$blue-color;
		width:100%;
		margin:0;
		padding:0;
	}
	.container {
		display: flex;
		align-content:center;
		padding: 65px 25px;
		color:#331;
		font-size:18px;
		line-height:25px;
		> div {
			margin: auto 0;
			width: calc(100% - 258px);
		}
	}
	h3 {
		border-right: solid 1px white;
		margin: auto 30px auto 0;
		padding: 40px 30px 40px 0;
		width: 198px;
	}
	a {
		display: block;
	}
	ul {
		margin: 0 0 10px 0;
		padding: 0;
		display: flex;
		//display:block;
		//flex-wrap: wrap;
		flex-direction:column;
		list-style: none;
		margin: auto;
		text-align: center;
		a.blue {
			color:#16438E;
			padding:0px 10px;
            display:inline-block;
            text-decoration:none;
            font-size: 18px;
            line-height: 25px;
            &:hover {
              color:#16438E;
            }
		}
	}
	li {
		margin-right: 30px;
		a {
			color: white;
			font-size: 13px;
			&:hover {
				color: white;
				text-decoration: underline;
			}
			&:focus {
				outline: none;
				color: inherit
			}
		}
	}
	p {
		font-size: 13px;
		line-height: 20px;
		+ p {
			margin: 0;
		}
	}
}
@media (max-width:600px){
	footer {
		.container {
			padding: 0;
			flex-direction: column;
			> div {
				padding: 30px 25px;
				width: 100%;
			}
		}
		h3 {
			width: 100%;
			border-right:none;
			border-bottom:1px solid #5c7cb0;
			text-align: center;
			margin: 0;
			padding: 30px 0;
		}
		ul {
			width: 100%;
			justify-content: space-between;
			padding-bottom: 5px;
		}
		li {
			margin: 0;
		}
		p {
			margin: 0;
			padding: 0;
		}
	}
}