@media screen and (max-width: 568px) {
	.modal .nine30 .btn-default {
		margin-bottom: 5px;
		width: 100%;
	}
	.tablet-only {
		display: none !important;
	}
	#rx {
		.container {
			padding: 125px 25px;
			.element {
				margin: 8px;
				width: calc(100%/2);
				.pill-bottle {
					margin-left: -80px;
					width: 370px;
				}
				h4 {
					font-size: 30px;
				}
			}
		}
	}
	#proven {
		.terms {
			margin-top: 5px;
		}
	}
}

@media screen and (max-width: 480px) {
	.mobile-only a.green-button {
		margin: 30px auto;
	}
	.container {
		padding: 0 25px;
		width: 100%;
	}
	#home .text-button {
		margin: 25px 0 20px 0;
	}
	.modal .nine30 .btn-default {
		font-size: 14px;
		text-align: center;
	}
	#pickup {
		.container {
			padding-bottom: 125px;
			padding-top: 125px;
			.blue-button {
				height: 150px;
				padding: 11px 8px;
				width: 185px;
				p {
					line-height: 27px;
				}
				&:nth-child(2) {
					padding-top: 25px;
				}
			}
			h2 {
				width: auto;
			}
			p {
				font-size: 18px;
			}
			.flex{
				display: block;
				.element {
					width: 100%;
					.green-button {
						margin: 50px auto;
					}
				}
			}
		}
	}
	#rx {
		.container {
			padding: 90px 10px 100px;
			.flex {
				display: block;
				.pill-bottle {
					margin-left: 30px;
					width: 390px;
				}
				.element {
					margin: 0;
					width: 100%;
					&:nth-child(2) {
						margin-top: 35px;
						text-align: left;
						h4 {
							font-size: 34px;
							line-height: 45px;
							margin-bottom: 20px;
						}
					}
				}
				.green-button {
					float: none;
					margin: 50px auto;
				}
			}
		}
	}
	#isi, #consistent {
		.container {
			p, ul {
				font-size: 13px;
				line-height: 20px;
			}
		}
	}
	.faq {
		padding-top: 140px;
	}
	.modal {
		overflow: scroll;
		.modal-content {
			font-size: 20px;
			margin-top: 40px;
			padding: 60px 10px 50px;
			width: 95%;
			a {
				display: block;
				margin: 20px auto;
			}
			a.cancel {
				float: none;
			}
		}
	}
	ul.terms li .mobile-only {
		li {
			margin: 0;
		}
	}
	#home {
		ul.terms {
			margin: 20px 50px;
			padding: 0 13px;
			li {
				width: auto;
			}
		}
	}
	.interested {
		margin-top: 70px;
	}
	.modal .nine30 .close {
		text-align: right;
		width: 100%;
	}
}


@media screen and (max-device-width : 414px) // or 213.4375em
	and (max-width : 414px) // or 23.4375em
	and (orientation : portrait) 
	and (device-pixel-ratio : 2)
	and (-webkit-min-device-pixel-ratio : 2) {
	#rx .container .flex .pill-bottle {
		margin-left: 0;
	}
}

@media screen and (max-device-width : 414px) {
	#home ul.terms li {
		margin: 0 13px 0 0;
	}
	#rx .container .flex .pill-bottle {
		margin-left: 0;
		width: 370px;
	}
	#home {
		ul.terms {
			margin: 20px 30px;
			padding: 0 0px;
			li {
				width: auto;
			}
		}
	}
}

@media screen and (max-device-width : 375px) {
	#rx .container .flex .pill-bottle {
		margin-left: -20px;
		width: 370px;
	}
	#home h2 {
		padding: 0 20px;
	}
	.modal {
		.modal-content {
			font-size: 20px;
			margin-top: 40px;
			padding: 40px 10px 30px;
			width: 95%;
			p {
				font-size: 16px;
			}
			a {
				display: block;
				margin: 20px auto;
			}
			a.cancel {
				float: none;
			}
		}
	}
	#consistent a#toggle {
		font-size: 12px;
	}
	ul.terms li {
		margin: 0 12px 0;
	}
	#isi .container h3, #consistent .container h3 {
		font-size: 15px;
	}
	#home {
		.text-button {
			margin-top: 20px;
		}
		ul.terms {
			margin: 30px 0;
			padding: 0;
			li {
				margin: 0px 8px 0;
			}
		}
	}
	.interested {
		margin-top: 90px;
	}
	#pickup .container .element h2 {
		font-size: 30px;
   		line-height: 40px;
   		margin-bottom: 5px;
	}
	#pickup .container {
		padding-bottom: 45px;
	}
	.faq h2 {
		font-size: 24px;
	}
	.faq h3 {
		font-size: 21px;
	}
}

@media screen and (max-device-width: 360px) and (max-width: 360px) {
	#isi, #consistent {
		padding: 10px;
	}
	.faq h3 {
		font-size: 21px;
	}
}

@media screen and (max-device-width: 320px) and (max-width: 320px) {
	.modal .nine30 .btn-default {
		font-size: 12px;
		padding-top: 10px;
		text-align: left;
	}
	#reminder .container {
		padding: 60px 10px;
	}
	#rx .container .flex .pill-bottle {
		margin-left: -20px;
		width: 305px;
	}
	#isi, #consistent {
		padding: 5px;
		.container {
			h3 {
				font-size: 15px;
			}
		}
	}
	ul.terms li {
		font-size: 11px;
		margin-right: 9px;
	}
	#rx .container .flex .green-button {
		width: auto;
	}
	#home {
		.text-button {
			margin-top: 20px;
		}
		ul.terms {
			margin: 30px 0;
			padding: 0;
			li {
				margin: 0px 4px 0;
			}
		}
	}
}