/*
	HEADER STYLES
 */
.brand-outer-container {
	-webkit-box-shadow: 0 0 4px 0 rgba(50,50,50,.75);
	-moz-box-shadow: 0 0 4px 0 rgba(50,50,50,.75);
	box-shadow: 0 0 4px 0 rgba(50,50,50,.75);
	height: 29px;
	width: 100%;
	background: #fff;
	max-width: 100%;
	position: fixed;
	z-index: 1100;
	top: 0;
	left: 0;

	.container {

		width: 95.8333333333%;
		max-width: 1150px;
		margin: 0 auto;
		height: 29px;
		padding: 0;

		a{
			padding-right: 6px;
			float: right;
			line-height: 29px;
			height: 29px;
			display: block;

			img {
				height: 23px;
				margin: 3px 0;
				display: block;
			}
		}
	}
}

.main-header {
	position: fixed;
	top: 29px;
	left: 0;
	z-index: 1030;
	background: linear-gradient(to right, $green-color, $blue-color);
	border-bottom: 1px solid white;
	width: 100%;

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: (1150 / 1200) * 100%;
		max-width: 1150px;
		margin: 0 auto;
	}
	h1 {
		height: 70px;
		margin: auto auto auto 0;
		a {
			display: block;
		}
	}
	ul {
		display: flex;
		margin: auto 0 auto auto;
		li {
			margin-right: 25px;
			list-style: none;
			a {
				color: white;
				font-size: 15px;
				display: block;
				padding: 1em 0;
				&.isiToggle {
					cursor:pointer;
				}
			}
		}
	}
	.green-button {
		border: none;
		border-radius: 0px;
		font-size: 15px;
		height: 90px;
		padding: 15px 0;
		text-align: center;
		width: 195px;
		cursor:pointer;
	}
}
@media (max-width:1200px){
	.brand-outer-container .container {
		width:100%;
	}
	.main-header {
		.inner {
			width: 100%;
			max-width:inherit;
		}
		h1 {
			margin: auto auto auto 20px;
		}
	}
}
@media (max-width:920px){
	.main-header {
		ul {
			flex-direction: column;
			align-items: flex-end;
			li {
				text-align: right;
				a {
					padding: 5px 0;
				}
			}
		}
	}
}
@media (max-width:740px){
	.main-header {
		.inner {
			flex-wrap:wrap;
		}
		h1 {
			order:2;
		}
		ul {
			order:1;
			flex-direction: row;
			align-items: center;
			width: 100%;
			background: #e1e1e1;
			margin: 0;
			padding: 0;
			li {
				text-align: center;
				width: 50%;
				padding: 0 8px;
				margin: 0;
				a {
					padding: 0.25em 0;
					color: black;
					font-family: $hel-bold;
					font-size: 12px;
					font-weight: bold;
					text-align: left;
				}
				&:first-child a {
					text-align: right;
				}
			}
		}
		.green-button {
			order:3;
		}
	}
}
@media (max-width:475px){
	.main-header {
		h1 {
			width: calc(100% - 215px);
			display: flex;
			align-items: center;
			margin: auto 10px;
			img {
				width: 100%;
			}
		}
		ul {
			justify-content:center;
			li {
				width: auto;
			}
			li:first-child {
				padding: 0 5px 0 0;
			}
			li:last-child {
				padding: 0 0 0 5px;
			}
		}
	}
}

@media (max-width: 414px) {
	.river-cinemagraph .container .info p {
		font-size: 32px !important;
	}
}
