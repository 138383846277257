@media screen and (max-width: 800px) {
	.tablet-only {
		display: block;
	}
	.tablet-hide {
		display: none;
	}
	#home .container {
		padding-left: 25px;
		padding-right: 25px;
		.flex {
			.element {
				.flex {
					display: block;
					.text-button {
						margin: 20px 0;
						padding: 0 3px;
						width: 100%;
					}
				}
			}
		}
	}
	#reminder .container {
		padding: 60px 20px;
	}
	#pickup {
		.container {
			padding-bottom: 125px;
			padding-top: 125px;
		}
		.element {
			margin-right: 40px;
			width: calc(100%/2 - 40px);
		}
		h2 {
			margin: 0;
			width: auto;
		}
	}
	#rx {
		.container {
			padding: 125px 25px;
			.element {
				margin: 8px;
				width: calc(100%/2 - 16px);
				.pill-bottle {
					margin-left: -25px;
					width: 370px;
				}
				h4 {
					font-size: 35px;
					line-height: 40px;
				}
			}
		}
	}
	#isi, #consistent {
		padding: 25px;
	}
	.modal .modal-content {
		height: auto;
		width: 88%;
	}
}

@media screen and (max-width: 667px) {
	.web-only {
		display: none;
	}
	.desktop-only {
		display: none !important;
	}
	.mobile-only {
		display: block !important;
		text-align: center;
		a.green-button {
			margin: 0 auto;
		}
		.text-button {
			margin-top: 20px;
			text-align: center;
			width: 100%;
		}
	}
	.terms {
		display: flex;
		li {
			text-align: center;
			width: calc(100%/2);
		}
	}
	#home {
		.container {
			padding: 100px 25px 60px;
		}
	}
	#home { 
		h2 {
			padding: 0 50px;
		}
		.flex {
			display: block;
			.element {
				width: 100%;
			}
		}
		.element {
			&:first-child {
				margin-bottom: 40px;
				.green-button {
					margin: 0 auto;
				}
			}
			.drug-checklist {
				margin-bottom: 50px;
			}
		}
	}
	#rx {
		.container {
			.flex {
				.element {
					width: calc(100%/2);
				}
			}
		}
	}
	#rx .container .flex .element:nth-child(2) {
		text-align: left;
	}
}